<template>
    <div class="alert-message-dialog" :class="info.colour">
        <div class="container">
            <i :class="info.icon" aria-hidden="true" />
            <span v-html="info.content"></span>
        </div>
    </div>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'
import type { Models } from '~/types/models'

export default defineComponent({
    props: {
        info: {
            type: Object as PropType<Models.HeaderMessage>,
            required: true,
        },
    },
})
</script>

<style lang="postcss" scoped>
.alert-message-dialog {
    .container {
        @apply flex items-center justify-center space-x-2 p-2;
        i {
            @apply h-5 w-5 text-xl leading-5;
        }
        span {
            @apply text-sm font-medium;
        }
    }
    &.red {
        @apply bg-[#FFD6DD] text-red-800;
    }
    &.green {
        @apply bg-[#dcfad4] text-green-800;
    }
    &.blue {
        @apply bg-[#c6ddff] text-blue-800;
    }
    &.yellow {
        @apply bg-[#fff8b7] text-yellow-800;
    }
    &.neutral {
        @apply bg-[#d6d6d6] text-gray-800;
        i {
            @apply text-blue-600;
        }
    }
}
</style>
